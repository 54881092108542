<script lang="tsx">
import type { SlotsType } from 'vue'
import { CoreUiCheckbox } from '#components'
import type { Getter } from '@core/types/utility'
import type { SizeProp } from '@core/types/components'
import type { ComponentPropsOverride } from '@core/types/components/CoreUiSelector'
import type { ComponentOverrideOptions } from '@core/types/components'

export type CoreUiSelectorProps<T> = {
    values: T[],
    /**
     * The size of the selector input.
     * @default 'md'
     */
    size?: SizeProp
    /**
     * Whether to allow multiple selections or not.
     * Converts the inputs from radios to checkboxes.
     */
    multiple?: boolean
    /**
     * Whether the selector is disabled or not.
     */
    disabled?: boolean
    /**
     * Whether the selector is loading or not.
     */
    loading?: boolean
    color?: ComponentPropsOverride['color']
    modelValue?: T | T[] | null | string[]
    /**
     * The aria label for the selector.
     * If not set, the translation key will be used with
     * a generic default value or the value of the `ariaLabelKey` prop.
     */
    ariaLabel?: string
    /**
     * The translation key for the aria label.
     * If the `ariaLabel` prop is set, this will be ignored.
     */
    ariaLabelKey?: string
    valueGetter?: Getter<T>
    labelGetter?: Getter<T>
    /**
     * Whether to use the index of the item as the html input value.
     * Can be set to a function to determine whether to use the index as the value or not
     * for the specific item.
     *
     * This should probably be only used in very specific cases & in the core.
     */
    useIndexAsValue?: boolean | ((item: T) => boolean)
    /**
     * Function to determine whether an item is disabled or not.
     */
    isItemDisabled?: (item: T) => boolean
    /**
     * Whether to render disabled items or not.
     * When set to `true`, disabled items will not be rendered.
     * @default false
     */
    hideDisabled?: boolean
    /**
     * Whether to only display the selector without any interactivity.
     * Setting this prop to `true` will not render the html inputs & the fieldset but rather
     * use regular divs.
     */
    displayOnly?: boolean
}

type CoreUiSelectorSlots<T> = {
    checkbox: { item: T, index: number, isSelected: boolean }
}

type ComponentOptions = {}

export function defineComponentCoreUiSelector<T>(_options?: ComponentOverrideOptions<ComponentOptions, CoreUiSelectorProps<T>, CoreUiSelectorSlots<T>>) {
    return defineComponent(
        (props: CoreUiSelectorProps<T>, ctx) => {
            const useIndexAsValue = computed(() => {
                const item = props.values[0]
                if (!item) return false
                if (typeof props.useIndexAsValue === 'function') return props.useIndexAsValue(item)
                return props.useIndexAsValue
            })
            const valueGetter = computed(() => props.valueGetter)
            const labelGetter = computed(() => props.labelGetter || 'label')

            const { t } = useI18n({ useScope: 'global' })

            const selectedItemValue = computed({
                get: () => {
                    if (props.multiple) {
                        return props.modelValue ?? []
                    }

                    if (useIndexAsValue.value) {
                        if (!props.modelValue) return null

                        // TODO: find out a better way to compare equality
                        // / maybe use the value getter but only to differentiate the objects when using
                        // / index as value?
                        const modelValue = JSON.stringify(props.modelValue)
                        return props.values.findIndex(value => JSON.stringify(value) === modelValue)
                    }

                    return getValueByGetter(props.modelValue as any, valueGetter.value)
                },
                set: (value) => {
                    if (props.multiple) {
                        ctx.emit('update:modelValue', value as any)
                        return
                    }

                    if (value === null) {
                        ctx.emit('update:modelValue', null)
                        ctx.emit('change', null)
                        return
                    }

                    if (value || typeof value === 'number') {
                        const isValueDifferent = useIndexAsValue.value
                            ? props.values[value as number] !== props.modelValue
                            : value !== props.modelValue

                        if (isValueDifferent) ctx.emit('change', useIndexAsValue.value ? props.values[value as number] : value as any)

                        ctx.emit('update:modelValue', useIndexAsValue.value ? props.values[value as number] : value as any)
                    }
                },
            })

            const radioGroup = `selector-${useId()}`

            const isDisabled = computed<boolean>(() => !!(props.disabled || props.loading))

            return () => !props.displayOnly
                // FUNCTIONAL version of the selector
                ? (
                    <fieldset class={['sim-selector', {
                        'sim-selector--loading': props.loading,
                        'sim-selector--disabled': isDisabled.value,
                    }]}>
                        <legend class="visually-hidden">
                            {props.ariaLabel || t(props.ariaLabelKey as string)}
                        </legend>

                        {props.values.map((item, index) => {
                            const isItemDisabled = (props.isItemDisabled ? props.isItemDisabled(item) : false) || isDisabled.value

                            if (props.hideDisabled && isItemDisabled) return null

                            return (
                                <CoreUiCheckbox
                                    inputClass={[
                                        'sim-selector__checkbox',
                                        {
                                            'sim-selector__checkbox--disabled': isItemDisabled,
                                            [`sim-selector__checkbox--${props.size}`]: props.size !== 'md',
                                            [`sim-selector__checkbox--c-${props.color}`]: !!props.color,
                                        },
                                    ]}
                                    disabled={isItemDisabled}
                                    key={getValueByGetter(item, valueGetter.value) as string ?? index}
                                    v-model={selectedItemValue.value}
                                    value={useIndexAsValue.value ? index : getValueByGetter(item, valueGetter.value) as any}
                                    aria-label={getValueByGetter(item, labelGetter.value)}
                                    type={props.multiple ? 'checkbox' : 'radio'}
                                    allow-radio-uncheck
                                    name={radioGroup}
                                >
                                    {{
                                        checkbox: () => renderSlot(ctx.slots.checkbox, _options?.slots?.checkbox, {
                                            item: item,
                                            index: index,
                                            isSelected: selectedItemValue.value === getValueByGetter(item, valueGetter.value),
                                        }, (
                                            <>
                                                {getValueByGetter(item, labelGetter.value)}
                                            </>
                                        )),
                                    }}
                                </CoreUiCheckbox>
                            )
                        })}
                    </fieldset>
                )
                // DISPLAY ONLY version of the selector
                : (
                    <div class={['sim-selector sim-selector--display-only', {
                        'sim-selector--loading': props.loading,
                        'sim-selector--disabled': isDisabled.value,
                    }]}>
                        {props.values.map((item, index) => {
                            const isItemDisabled = (props.isItemDisabled ? props.isItemDisabled(item) : false) || isDisabled.value
                            if (props.hideDisabled && isItemDisabled) return null

                            return (
                                <div
                                    key={getValueByGetter(item, valueGetter.value) as string ?? index}
                                    class={['sim-selector__checkbox', {
                                        'sim-selector__checkbox--disabled': isItemDisabled,
                                        [`sim-selector__checkbox--${props.size}`]: props.size !== 'md',
                                        [`sim-selector__checkbox--c-${props.color}`]: props.color,
                                    }]}
                                >
                                    {
                                        renderSlot(ctx.slots.checkbox, _options?.slots?.checkbox, {
                                            item: item,
                                            index: index,
                                            isSelected: selectedItemValue.value === getValueByGetter(item, valueGetter.value),
                                        }, (
                                            <>
                                                {getValueByGetter(item, labelGetter.value)}
                                            </>
                                        ))
                                    }
                                </div>
                            )
                        })}
                    </div>
                )
        },
        {
            props: {
                values: {
                    type: Array as PropType<CoreUiSelectorProps<T>['values']>,
                    default: _options?.props?.values?.default,
                    required: _options?.props?.values?.required ?? false,
                },
                size: {
                    type: String as PropType<CoreUiSelectorProps<T>['size']>,
                    default: _options?.props?.size?.default ?? 'md',
                    required: _options?.props?.size?.required ?? false,
                },
                multiple: {
                    type: Boolean as  PropType<CoreUiSelectorProps<T>['multiple']>,
                    default: _options?.props?.multiple?.default ?? false,
                    required: _options?.props?.multiple?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<CoreUiSelectorProps<T>['disabled']>,
                    default: _options?.props?.disabled?.default ?? false,
                    required: _options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<CoreUiSelectorProps<T>['loading']>,
                    default: _options?.props?.loading?.default ?? false,
                    required: _options?.props?.loading?.required ?? false,
                },
                color: {
                    type: String as PropType<CoreUiSelectorProps<T>['color']>,
                    default: _options?.props?.color?.default,
                    required: _options?.props?.color?.required ?? false,
                },
                modelValue: {
                    type: [String, Array, Object] as PropType<CoreUiSelectorProps<T>['modelValue']>,
                    default: _options?.props?.modelValue?.default,
                    required: _options?.props?.modelValue?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<CoreUiSelectorProps<T>['ariaLabel']>,
                    default: _options?.props?.ariaLabel?.default,
                    required: _options?.props?.ariaLabel?.required ?? false,
                },
                ariaLabelKey: {
                    type: String as PropType<CoreUiSelectorProps<T>['ariaLabelKey']>,
                    default: _options?.props?.ariaLabelKey?.default ?? '_core_simploshop.labels.selector',
                    required: _options?.props?.ariaLabelKey?.required ?? false,
                },
                valueGetter: {
                    type: [Function, String, Number, Symbol] as PropType<CoreUiSelectorProps<T>['valueGetter']>,
                    default: _options?.props?.valueGetter?.default,
                    required: _options?.props?.valueGetter?.required ?? false,
                },
                labelGetter: {
                    type: [Function, String, Number, Symbol] as PropType<CoreUiSelectorProps<T>['labelGetter']>,
                    default: _options?.props?.labelGetter?.default,
                    required: _options?.props?.labelGetter?.required ?? false,
                },
                useIndexAsValue: {
                    type: [Boolean, Function] as PropType<CoreUiSelectorProps<T>['useIndexAsValue']>,
                    default: _options?.props?.useIndexAsValue?.default ?? true,
                    required: _options?.props?.useIndexAsValue?.required ?? false,
                },
                isItemDisabled: {
                    type: Function as PropType<CoreUiSelectorProps<T>['isItemDisabled']>,
                    default: _options?.props?.isItemDisabled?.default,
                    required: _options?.props?.isItemDisabled?.required ?? false,
                },
                hideDisabled: {
                    type: Boolean as PropType<CoreUiSelectorProps<T>['hideDisabled']>,
                    default: _options?.props?.hideDisabled?.default ?? false,
                    required: _options?.props?.hideDisabled?.required ?? false,
                },
                displayOnly: {
                    type: Boolean as PropType<CoreUiSelectorProps<T>['displayOnly']>,
                    default: _options?.props?.displayOnly?.default ?? false,
                    required: _options?.props?.displayOnly?.required ?? false,
                },
            },
            slots: Object as SlotsType<CoreUiSelectorSlots<T>>,
            emits: {
                'update:modelValue': (value: CoreUiSelectorProps<T>['modelValue']) => true,
                'change': (value: Required<CoreUiSelectorProps<T>>['modelValue']): any => true,
            },
        }
    )
}

export default defineComponentCoreUiSelector()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiSelector.scss" as *;

</style>
